<div class="fixed top-0 left-0 right-0">
  <div class="max-w-screen-sm mx-auto p-4 empty:hidden flex flex-col gap-4">
    @for(item of bar.snacks().values(); track item.id){
    <div @snack class="snack {{ item.snack.type }} p-4 rounded-md z-[100] shadow-md"
      [ngClass]="{'cursor-pointer': !!item.click}" (click)="item.click?.()">
      <div class="flex flex-row items-center gap-4">
        @if(item.snack.icon){
        <div>
          <span class="material-symbols-outlined text-lg">{{ item.snack.icon }}</span>
        </div>
        }
        <div class="flex-1">{{ item.snack.message }}</div>
        <div>
          <button (click)="$event.stopPropagation(); item.remove()">
            <span class="material-symbols-outlined">close</span>
          </button>
        </div>
      </div>
    </div>
    }
  </div>
</div>

@if(debug()){
<div class="fixed bottom-0 left-0 bg-white z-50 p-4">
  <div class="flex flex-row gap-1">
    <button class="outline-button" (click)="bar.error('This is an error')">Show Error</button>
    <button class="outline-button" (click)="bar.info('This is an info')">Show Info</button>
    <button class="outline-button" (click)="bar.success('This is a success')">Show Success</button>
    <button class="outline-button"
      (click)="bar.add({message: 'Important Message', icon: 'warning', type: 'error', important: true})">
      Show Important
    </button>
  </div>
</div>
}