import { inject, Injectable, signal } from '@angular/core';
import { Snack } from './snack';

export interface SnackConfig {
  id: number;
  snack: Snack;
  remove: () => void;
  click?: () => void;
}

@Injectable({ providedIn: 'root' })
export class SnackBar {
  snacks = signal<Map<number, SnackConfig>>(new Map());

  add(snack: Snack, click?: () => void) {
    // Create a unique ID for the snack.
    const id = Date.now();
    let timeout: number | undefined = undefined;

    const remove = () => {
      window.clearTimeout(timeout);
      this.remove(id);
    };

    const config: SnackConfig = {
      id,
      snack,
      click: click
        ? () => {
            remove();
            click?.();
          }
        : undefined,
      remove,
    };

    this.snacks.update((snacks) => snacks.set(id, config));

    if (!snack.important) {
      timeout = window.setTimeout(() => config.remove(), 5000);
    }
  }

  remove(id: number) {
    this.snacks.update((snacks) => {
      snacks.delete(id);
      return snacks;
    });
  }

  info(message: string, click?: () => void) {
    this.add({ message, type: 'info', icon: 'info' }, click);
  }

  error(message: string, click?: () => void) {
    this.add({ message, type: 'error', icon: 'info' }, click);
  }

  success(message: string, click?: () => void) {
    this.add({ message, type: 'success', icon: 'info' }, click);
  }
}
