import { Component, inject, input } from '@angular/core';
import { SnackBar } from './snack-bar';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.css'],
  animations: [
    trigger('snack', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-100%)' }),
        animate('300ms', style({ opacity: 1, transform: 'translateY(0)' })),
      ]),
      transition(':leave', [
        animate('300ms', style({ opacity: 0, transform: 'translateX(-50%)' })),
      ]),
    ]),
  ],
})
export class SnackBarComponent {
  timeout = input(5000);
  debug = input(false);

  protected bar = inject(SnackBar);
}
